import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import { css } from '@emotion/react'

const HeroContainer = styled.div`
    font-size: calc(16px + 6 * ((100vw - 320px) / 680));
    min-height: ${props => (props.large ? '500px' : '300px')};
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: ${props => props.centered && 'center'};

    @media (min-width: 769px) {
        align-items: ${props => (props.large ? 'center' : 'flex-end')};
        min-height: ${props =>
            props.large
                ? 'clamp(600px, 70vh, 800px)'
                : 'clamp(300px, 30vh, 400px)'};
    }
`

const stretchedCss = css`
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`

const HeroImage = styled(Img)`
    ${stretchedCss}
`

const HeroOverlay = styled.div`
    ${stretchedCss}
    background: transparent linear-gradient(284deg, #00274D 0%, #00305F 57%, #002A5C 100%) 0% 0% no-repeat padding-box;
    opacity: 0.6;
`

const HeroContent = styled.div`
    color: #fff;
    padding: 2rem var(--site-padding-x);
    position: relative;
    z-index: 2;
    text-align: ${props => props.centered && 'center'};
`

const ImpactText = styled.h2`
    font-size: 2.25rem;
    font-weight: 900;
    text-transform: uppercase;

    @media (min-width: 769px) {
        font-size: 4rem;
    }
`

const Hero = ({
    children,
    image,
    title = '',
    overlay = true,
    minHeight,
    large = false,
    className,
    centered = true,
}) => (
    <HeroContainer large={large} centered={centered} className={className}>
        {image && (
            <HeroImage
                fluid={image.childImageSharp.fluid}
                objectFit="cover"
                objectPosition="bottom left"
            />
        )}

        {overlay && <HeroOverlay />}

        <HeroContent centered={centered}>
            {title && <ImpactText>{title}</ImpactText>}
            {children}
        </HeroContent>
    </HeroContainer>
)

Hero.propTypes = {
    title: PropTypes.node,
}

export default Hero
